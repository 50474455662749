<template>
  <div class="search-page">
    <!-- 搜索栏 -->
    <div class="part-1">
      <van-icon name="arrow-left" size="0.55rem" @click="prevPageWhenInputHide" />
      <div class="search-box">
        <van-search v-model="keywords" shape="round" placeholder="搜索课程" />
        <van-button class="search-button" round color="linear-gradient(90deg, #5abffe 0%, #389cf8 61%, #1578f2 100%)" @click="toSearchResultPage" autofocus>搜索</van-button>
      </div>
    </div>
    <div class="part-2">
      <div class="part-title">
        <h5>热门搜索</h5>
      </div>
      <ul class="label-list" v-if="hotList.length > 0">
        <li v-for="(item, index) in hotList" :key="index" @click="chooseLabel(item)">{{ item }}</li>
      </ul>
      <div class="not-data" v-else>暂无数据</div>
    </div>
    <div class="part-3">
      <div class="part-title">
        <h5>历史搜索</h5>
        <van-icon name="delete-o" size="0.45rem" color="#c0c0c0" @click="clearHistoryList" v-if="historyList.length > 0" />
      </div>
      <ul class="label-list" v-if="historyList.length > 0">
        <template v-for="(item, index) in historyList">
          <li v-if="index < showCount" :key="'a' + index" @click="chooseLabel(item)">{{ item }}</li>
        </template>
        <template v-if="historyStatus">
          <template v-for="(item, index) in historyList">
            <li v-if="index >= showCount" :key="'b' + index" @click="chooseLabel(item)">{{ item }}</li>
          </template>
        </template>
        <li @click="changeHistoryStatus" v-if="historyList.length > showCount">
          <van-icon v-show="!historyStatus" name="arrow-down" />
          <van-icon v-show="historyStatus" name="arrow-up" />
        </li>
      </ul>
      <div class="not-data" v-else>暂无数据</div>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash.debounce'
import './search.less'
export default {
  data() {
    return {
      historyStatus: false, // 历史搜索列表是否展开
      historyList: [], // 历史搜索记录
      showCount: 5, // 默认显示条数
      hotList: [], // 热门搜索
      keywords: this.$route.query.keywords ? this.$route.query.keywords : '', // 输入框关键字
      isBack: '',
    }
  },
  methods: {
    pushHistory() {
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        // this.$Toast.fail('请点击左上角的返回键进行返回')
        this.toPrevPage()
      }
    },
    prevPageWhenInputHide() {
      const handler = debounce(
        () => {
          this.toPrevPage()
          window.removeEventListener('resize', handler)
        },
        32,
        {
          leading: false,
          trailing: true,
        }
      )
      window.addEventListener('resize', handler)
      requestAnimationFrame(handler)
    },
    // 切换历史搜索列表展开状态
    changeHistoryStatus() {
      this.historyStatus = !this.historyStatus
    },
    // 清空历史搜索
    clearHistoryList() {
      this.historyList = []
      localStorage.keywords = '[]'
    },
    // 返回上一页
    toPrevPage() {
      let temp = localStorage.search_page_source
      if (this.isBack === 'Y' && temp) {
        this.$router.push({ path: temp })
      } else {
        this.$router.go(-1)
      }
    },
    // 点击标签
    chooseLabel(val) {
      this.keywords = val
      this.toSearchResultPage()
    },
    // 跳转至搜索结果页
    toSearchResultPage() {
      if (this.keywords.trim()) {
        let keywords = localStorage.keywords ? JSON.parse(localStorage.keywords) : []
        if (keywords.length > 9) {
          keywords = keywords.splice(0, 1)
        }
        keywords.indexOf(this.keywords.trim()) === -1 && keywords.push(this.keywords.trim())
        localStorage.keywords = JSON.stringify(keywords)
        this.$router.replace({
          name: 'searchResult',
          query: {
            keywords: this.keywords,
          },
        })
      }
    },
    getHotwords() {
      this.$axios
        .get('/course/front/permit/query/hotwords')
        .then((res) => {
          if (res.data.status === 1) {
            this.hotList = res.data.data
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
  },
  created() {
    this.getHotwords()
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  mounted() {
    this.historyList = localStorage.keywords ? JSON.parse(localStorage.keywords).reverse() : []
    // 使输入框聚焦
    document.querySelector('.van-field__control').focus()
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>
